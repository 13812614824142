import { TextContent } from "./_types"

export const TEXT_CONTENT: TextContent = {
  heading: 'Coming soon',
  title: 'Inter&Co has you covered in Brazil!',
  description: 'Your super app will be your ideal travel companion. A range of new features is coming soon to help you make the most of your time in Brazil.',
  menuOptions: [
    {
      icon: 'brands/ic_pix',
      title: 'Pix',
      description: 'Seamless local transfers. You will be able to send and receive money within Brazil.',
    },
    {
      icon: 'brands/ic_inter_loop',
      title: 'Loop',
      description: 'You will continue to earn Loop points during your stay.',
    },
    {
      icon: 'shopping/ic_gift',
      title: 'Marketplace',
      description: 'Discover more brands and shop at our Brazil marketplace. All on your super app!',
    },
    {
      icon: 'finance/ic_cashback',
      title: 'Cashback',
      description: 'Purchase gift cards from local brands and receive cashback. A great way to save on your travels!',
    },
  ],
}

export const PRODUCT_NAME = 'coming-soon'
export const BANNER_IMAGE_URL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/webview_banner_coming_soon/image.webp'
export const FLUXO_TAG = 'COMINGSOON_ACESSO_FLUXO'
export const ACESSO_TAG = 'COMINGSOON_ACESSO'
