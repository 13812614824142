import { primary } from "src/styles/newColors"
import styled from "styled-components"

export const CardWithButton = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 0;
  display: grid;
  border-radius: 0.75rem;
  display: flex;
  grid-template-rows: 22px minmax(62px, 1fr) 16px;
  align-items: center;

  .card__text-container {
    margin-left: 1rem;
  }

  .card__icon {
    height: 24px;
    width: 24px;
  }

  .card__link,
  .card__title {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .card__description {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 0;
    color: var(--gray400);
  }

  .card__title {
    color: var(--gray500);
    align-self: end;
    margin-bottom: 0.5rem;
  }
`
