/* eslint-disable no-unused-expressions */
import { ACESSO_TAG, FLUXO_TAG, PRODUCT_NAME, TEXT_CONTENT } from "../_constants"
import { InterBridge } from "../hooks/_useInterBridgeInstance"

export const tagPageLoad = async (iWb: InterBridge | undefined) => {
  return iWb?.interWbSession.requestAnalytics(ACESSO_TAG, {
    screen: TEXT_CONTENT.heading,
    content_action: "Dado carregado",
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  })
}

export const tagBackClick = async (iWb: InterBridge | undefined) => {
  return iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: TEXT_CONTENT.heading,
    ref_type: "botao",
    content_action: "Toque",
    action_id: "Voltar",
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  })
}
