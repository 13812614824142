/* eslint-disable no-unused-expressions */
import React from 'react'
import { AppBar } from '@interco/inter-ui/components/AppBar'
import { useInterBridgeInstance } from '../../hooks/_useInterBridgeInstance'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { Button } from '@interco/inter-ui/components/Button'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { primary } from 'src/styles/newColors'

type ComingSoonAppHeaderProps = {
  heading: string;
  onBackClick?: () => void;
}

const Header = styled.header`
  & span {
    font-family: 'Inter', sans-serif !important;
  }
  svg {
    width: 24px;
    height: 24px;
  }

  button {
    padding: 0.25rem;
  }

  .help-btn-placeholder {
    height: 32px;
    width: 32px;
  }
`

const ComingSoonAppHeader: React.FC<ComingSoonAppHeaderProps> = ({ heading, onBackClick }: ComingSoonAppHeaderProps) => {
  const iWb = useInterBridgeInstance()

  const handleBackClick = async () => {
    onBackClick?.()
    await iWb?.interWbNavigate.requestGoBack()
  }

  return (
    <Header>
      <Helmet>
        <meta name='robots' content='noindex, nofollow' />
        <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Inter&display=swap' />
        <link href='https://cdn.bancointer.com.br/inter-assets/latest/css/citrina.css' rel='stylesheet' />
      </Helmet>
      <AppBar
        leftIcon={
          <Button variant='link' onClick={handleBackClick}>
            <IconsSwitch color='var(--primary500)' size='XL' icon='action-navigation/ic_arrow_left' lib='interco' />
          </Button>
          }
      >
        {heading}
      </AppBar>
    </Header>
  )
}

export default ComingSoonAppHeader
