import { gray } from 'src/styles/newColors'
import styled from 'styled-components'

type TesouroMePoupeAppStyles = {
  isFaqVisible: boolean;
  isVideoVisible: boolean;
}

/* 550px is the defined height of FAQ on figma + some spacing for padding  */
  /* this needs to be set because of page slide container being absolute positioned
  and not taking up space in the DOM
  */
const getContainerHeight = (isFaqVisible: boolean, isVideoVisible: boolean) => {
  if (isFaqVisible) {
    return '600px'
  }
  if (isVideoVisible) {
    return 'calc(100vh - 54px)'
  }
  return 'fit-content'
}

export const ComingSoonAppSection = styled.section<TesouroMePoupeAppStyles>`
  padding: 0 1.5rem;
  position: relative;
  padding-bottom: ${(props: TesouroMePoupeAppStyles) => props.isFaqVisible || props.isVideoVisible ? 0 : '1.5rem'};
  height: ${(props: TesouroMePoupeAppStyles) => getContainerHeight(props.isFaqVisible, props.isVideoVisible)};
  width: 100vw;
  overflow-x: hidden;

  .banner-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0;
  }

  .video-embeded-frame {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 1.5rem;
    color: var(--gray500);
  }

  .description {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: var(--gray400);
  }

  .button-block {
    margin-bottom: 1.5rem;
    text-decoration: none;
    display: block;
  }

  a {
    text-decoration: none !important;
  }
`

export const CardsGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  height: fit-content;
  list-style: none;
  margin-bottom: 1rem;

  li {
    border-bottom: 1px solid #DEDFE4;

    &:last-child {
      border-bottom: none;
    }
  }
`

export const BannerBox = styled.div`
  display: grid;
  grid-template-columns: minmax(109px, 1fr) 2fr;
  column-gap: 0.75rem;

  .banner-box__title {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0.5rem;
    color: ${gray[500]};
  }

  .banner-box__body {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${gray[400]};
  }
`

export const MobileFrame = styled.div`
  background-color: var(--neutral-theme);
  @media (min-width: 768px) {
    padding-top: 56px;
    position: relative;
    width: 100%;
    max-width: 375px;
    height: 100%;
    display: flex;
    margin: 0 auto;
  }
`
