import { useEffect, useState } from "react"

import { initTheme, Theme } from "@interco/inter-ui"
export type InterBridge = typeof import("@interco/inter-webview-bridge")

const getTheme = (application: string) => (application === 'PJ' ? Theme.PJ : Theme.PF)

export const useInterBridgeInstance = () => {
  const [ iWb, setIWb ] = useState<InterBridge>()

  useEffect(() => {
    import('@interco/inter-webview-bridge').then((interBridge: InterBridge) => {
      setIWb(interBridge)

      interBridge.interWbSession.getAppInfo().then((iWbAppInfo) => {
        const { application, isDarkMode } = iWbAppInfo

        const theme = getTheme(application)

        initTheme(theme, !!isDarkMode)
      })
      .catch(() => {
        initTheme(Theme.PF, false)
      })

      const bridgeInstance = interBridge.default.getInstance()
      bridgeInstance.addWebviewEventListener(interBridge.WbSessionActions.GO_BACK, interBridge.interWbNavigate.requestGoBack)
      return () => bridgeInstance.removeWebviewEventListener(interBridge.WbSessionActions.GO_BACK, interBridge.interWbNavigate.requestGoBack)
    })
  }, [])

  return iWb
}
